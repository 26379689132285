import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    constructor() { 
                // Inject dependencies /set the default values for properties/ initialization code here. 
    
    }

    public scrollDispatcher$: BehaviorSubject<any> = new BehaviorSubject(null);
}