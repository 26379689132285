import { Injectable } from '@angular/core';
import { ConsumerAppEnvironment } from 'visenvironment';

export enum LogSeverity {
    INFO,
    GOOD,
    BAD,
    WARN,
    INTERCEPT,
    CACHE_ADD,
    CACHE_REMOVE,
    CACHE_READ,
    CACHE_NOT_FOUND,
    SECURITY
}

@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    constructor() {
        // Inject dependencies /set the default values for properties/ initialization code here. 
     }

    private _interceptionDebugFlag = 'vcld_cache';
    private _securityDebugFlag = 'vcld_security_debug';
    private _debugFlag = 'vcld_debug';

    private _isDebugAllowed = true;

    private _isInterceptorDebugAllowed = localStorage.getItem(this._interceptionDebugFlag) === 'true' && !ConsumerAppEnvironment.production;

    private _isSecurityDebugAllowed = localStorage.getItem(this._securityDebugFlag) === 'true' && !ConsumerAppEnvironment.production;

    /**
     * Prints a message to the JavaScript console when debugging is active
     * @param severity determines the format of the message
     * @param args arguments
     */
    public log(severity: LogSeverity, ...args: any[]): void {
        if (!this._isDebugAllowed) return;

        const bold = 'font-weight: bold;';
        const s = 'color: #64DCA0; ' + bold;
        const f = 'color: #f75757; ' + bold;
        const i = 'color: #57bcf7; ' + bold;
        const w = 'color: #FAFA64; ' + bold;

        const clear = '';

        switch (severity) {
            case LogSeverity.INFO:
                console.log(`%cINFO 💡 %c[Debug]%c`, i, bold, clear, ...args);
                break;
            case LogSeverity.GOOD:
                console.log(`%c  OK 👍 %c[Debug]%c`, s, bold, clear, ...args);
                break;
            case LogSeverity.BAD:
                console.log(`%cFAIL 👎 %c[Debug]%c`, f, bold, clear, ...args);
                break;
            case LogSeverity.WARN:
                console.log(`%cWARN 😳 %c[Debug]%c`, w, bold, clear, ...args);
                break;
            case LogSeverity.INTERCEPT:
                if (!this._isInterceptorDebugAllowed) break;
                console.log(`%cINFO 🚨 %c[-HTTP]%c`, i, bold, clear, ...args);
                break;
            case LogSeverity.CACHE_ADD:
                if (!this._isInterceptorDebugAllowed) break;
                console.log(`%c ADD 🌟 %c[Cache]%c`, s, bold, clear, ...args);
                break;
            case LogSeverity.CACHE_REMOVE:
                if (!this._isInterceptorDebugAllowed) break;
                console.log(`%c DEL 🗑️ %c[Cache]%c`, f, bold, clear, ...args);
                break;
            case LogSeverity.CACHE_READ:
                if (!this._isInterceptorDebugAllowed) break;
                console.log(`%cREAD 🧾 %c[Cache]%c`, i, bold, clear, ...args);
                break;
            case LogSeverity.CACHE_NOT_FOUND:
                if (!this._isInterceptorDebugAllowed) break;
                console.log(`%cMISS 💤 %c[Cache]%c`, f, bold, clear, ...args);
                break;
            case LogSeverity.SECURITY:
                if (!this._isSecurityDebugAllowed) break;
                console.log(`%cAUTH 🔐 %c[-HTTP]%c`, i, bold, clear, ...args);
                break;
            default:
                console.log(...args);
                break;
        }
    }
}
