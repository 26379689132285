import { Observable } from 'rxjs';
import { CoatingCatalogGroup, CoatingObj } from './../../../configs/lensSettings.mock';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Frame } from '../../models/frame.model';
import { TintsCoatingsService } from '../../services/tints-coatings.service';

@Component({
  selector: 'app-coating-catalog-host',
  templateUrl: './coating-catalog-host.component.html',
  styleUrls: ['./coating-catalog-host.component.scss']
})
export class CoatingCatalogHostComponent implements OnInit {

  
  @Input()
  public selectedFrame$: BehaviorSubject<Frame>;

  @Input()
  public selectedCoating$: BehaviorSubject<string> = null;

  @Output()
  public onCoatingClick: EventEmitter<string> = new EventEmitter();

  coatings$: Observable<CoatingCatalogGroup[]> = this._tintsCoatings.isTintsAndCoatingLoaded$.pipe(
    map(isLoaded => {
        if (!isLoaded) return [];
        const coatingArray = this._tintsCoatings.getAllLenses();
        const coatingGroups = this.groupbyKey(coatingArray, 'coatingGroupId');

        const keys = Object.keys(coatingGroups);
        let coatingsList = [] as CoatingCatalogGroup[];
        for(let i = 0; i < keys.length; i++) {
          let obj = {
            key: keys[i],
            value: coatingGroups[keys[i]]
          } as CoatingCatalogGroup;

          coatingsList.push(obj);
        }
        return coatingsList;
    })
  );

  coatingTooltip$:Observable<boolean[]> = this.coatings$.pipe(
    map(coatingsGroups => {
      return coatingsGroups.map(group => group.value.every(x => x.defaultTint !== null && x.defaultTint !== undefined));
    })
  )

  constructor(public _tintsCoatings: TintsCoatingsService, public translate: TranslateService) { }

  ngOnInit(): void {
     //Initialize here
  }

  groupbyKey(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  selectCoating(catalogCode) {
    this.onCoatingClick.emit(catalogCode);
  }
}
