import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vcld-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss']
})
export class LoadingDotsComponent {
  @Input()
  public showText: boolean = false;

  constructor() { 
            // Inject dependencies /set the default values for properties/ initialization code here. 
    
  }
}
