import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService, GTMCustomEvents } from '../../services/gtm.service';
import { DebugService } from '../../../services/debug.service';
import { HostListener } from '@angular/core';
import { Language } from '../../../configs/constant.flags';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { LanguageService } from '../../../services/language.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-intro-dialog',
    templateUrl: './intro.dialog.component.html',
    styleUrls: ['./intro.dialog.component.scss']
})
export class IntroDialogComponent {

    public isLinear = false;
    public screenWidth: number;

    public imageHeight: number;

    public TryOnImage: string;
    public FavoritesImage: string;
    public CatalogImage: string;
    private selectedLanguage: string = "en-US";

    constructor(private _formBuilder: UntypedFormBuilder, private debug: DebugService, private languages:LanguageService) {
        this.getScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth <= 600) {
            this.changeImageLanguageSmall(this.selectedLanguage);
        } else {
            this.changeImageLanguageHuge(this.selectedLanguage);
        }
    }

    async ngOnInit() {
        await this.languages.fetchLanguages();
        this.imageHeight = (Math.floor(window.innerHeight / 2));
        let availableLanguages = this.languages.getLanguages;
        if(window.innerWidth > 639 && window.innerWidth <= 979 && window.innerWidth > window.innerHeight) {
            if(availableLanguages.find(Language => Language.code === localStorage.getItem('vcldlang'))){
                this.selectedLanguage = localStorage.getItem('vcldlang');
                this.changeImageLanguageSmallLandscape(this.selectedLanguage);
            }else{
                this.changeImageLanguageSmallLandscape("en-US");
            }
        } else if (this.screenWidth <= 600) {
            if(availableLanguages.find(Language => Language.code === localStorage.getItem('vcldlang'))){
                this.selectedLanguage = localStorage.getItem('vcldlang');
                this.changeImageLanguageSmall(this.selectedLanguage);
            }else{
                this.changeImageLanguageSmall("en-US");
            }
        } else {
            if(availableLanguages.find(Language => Language.code === localStorage.getItem('vcldlang'))){
                this.selectedLanguage = localStorage.getItem('vcldlang');
                this.changeImageLanguageHuge(this.selectedLanguage);
            }else{
                this.changeImageLanguageHuge("en-US");
            }
        }
    }

    public changeImageLanguageHuge(lang: string): void {
        this.TryOnImage = `${environment.connectivity.introImages}/desktop/TryOn_${lang}.jpg`;
        this.FavoritesImage = `${environment.connectivity.introImages}/desktop/Favorites_${lang}.jpg`;
        this.CatalogImage = `${environment.connectivity.introImages}/desktop/Catalog_${lang}.jpg`;
    }

    public changeImageLanguageSmall(lang: string): void {
        this.TryOnImage = `${environment.connectivity.introImages}/mobile/TryOn_${lang}.jpg`;
        this.FavoritesImage = `${environment.connectivity.introImages}/mobile/Favorites_${lang}.jpg`;
        this.CatalogImage = `${environment.connectivity.introImages}/mobile/Catalog_${lang}.jpg`;
    }

    public changeImageLanguageSmallLandscape(lang: string): void {
        this.TryOnImage = `${environment.connectivity.introImages}/landscape/TryOn_${lang}.jpg`;
        this.FavoritesImage = `${environment.connectivity.introImages}/landscape/Favorites_${lang}.jpg`;
        this.CatalogImage = `${environment.connectivity.introImages}/landscape/Catalog_${lang}.jpg`;
    }
}