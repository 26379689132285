import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TintObj } from '../../../configs/lensSettings.mock';
import { Frame } from '../../models/frame.model';
import { TintsCoatingsService } from '../../services/tints-coatings.service';

@Component({
  selector: 'app-tint-catalog-host',
  templateUrl: './tint-catalog-host.component.html',
  styleUrls: ['./tint-catalog-host.component.scss']
})
export class TintCatalogHostComponent implements OnInit {

  @Input()
  public selectedFrame$: BehaviorSubject<Frame>;

  @Input()
  public selectedTint$: BehaviorSubject<string> = null;

  @Input()
  public selectedCoating$: BehaviorSubject<string> = null;

  @Output()
  public onTintClick: EventEmitter<string> = new EventEmitter();

  public translationObjName: string = 'localization';
  public tintIdPrefix: string = 'tint_';

  tints$ = this._tintsCoatings.isTintsAndCoatingLoaded$.pipe(
    map(isLoaded => {
        if (!isLoaded) return [];
        const tintsArray = this._tintsCoatings.getAllTints();
        return this.groupbyKey(tintsArray, 'vcldTintGroupId')
    })
  );

  tintCatalogNames$ = this.tints$.pipe(
    map(x => Object.keys(x))
  );

  constructor(public _tintsCoatings: TintsCoatingsService, public translate: TranslateService) { }
  

  ngOnInit(): void {
     //Initialize here
  }

  groupbyKey(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  selectTints(catalogCode) {
    this.onTintClick.emit(catalogCode);
  }

  public hasTintRestrictions(tint: TintObj): boolean {
    if (this.selectedCoating$.getValue()) {
        const t = this._tintsCoatings.getLensByCatalogCode(this.selectedCoating$.getValue());
        if (t && t.tintRestrictions)
            return t.tintRestrictions?.includes(tint?.vcldTintGroupId);
        return false;    
    }

    if(!this.selectedFrame$.getValue()) return true;

    return false;
  }

}
