import { FavoriteFrame } from './../../models/avatarcreationsession';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription, combineLatest, defer, Subscriber } from 'rxjs';
import { finalize, first, map, mergeMap, shareReplay, tap, switchMap } from 'rxjs/operators';
import { CoatingObj, TintObj } from '../../../configs/lensSettings.mock';
import { DebugService } from '../../../services/debug.service';
import { Frame, FrameAvailability } from '../../models/frame.model';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { GoogleTagManagerService, GTMCustomEvents } from '../../services/gtm.service';
import { SessionService } from '../../services/session.service';
import { FrameService } from '../../services/frame.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'vis-frame-host',
    templateUrl: './frame-host.component.html',
    styleUrls: ['./frame-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrameHostComponent implements OnInit, OnChanges {

    private sessionId: string;

    private compareList: Array<string> = [];
    private compareFrame: string;

    @Input()
    public frame: Frame;

    @Input()
    public priceFlag: boolean;

    @Input()
    public materialFlag: boolean;

    @Input()
    public modelNameFlag: boolean;

    @Input()
    public dmtFlag: boolean = false;

    @Input()
    public selectedFrame$: BehaviorSubject<Frame>;

    @Input()
    public coating?: BehaviorSubject<string> = null;

    @Input()
    public tint?: BehaviorSubject<string> = null;

    @Output()
    public onCompare: EventEmitter<string[]> = new EventEmitter();

    public isFrameAvailable : boolean;

    public isFrameTemporaryUnavailable : boolean;

    public isFramePermanentlyUnavailable : boolean;

    public favoritedFrames$: Observable<FavoriteFrame[]> = of([]);
    public isFavorite$: Observable<Boolean> = of(false);

    public isSelected$: Observable<Boolean> = of(false);

    public isFramefavorizing$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private debug: DebugService, 
        private gtm: GoogleTagManagerService, 
        private router: Router, 
        private cd: ChangeDetectorRef,
        private formatPrice:FormatPricePipe,
        private _session:SessionService,
        private _frames: FrameService,
        private _datacache:DataService) { }

    ngOnInit(): void {
        this.favoritedFrames$ = this._session.selectedSession$.pipe(
            tap(session => this.sessionId = session.id),
            map(session => session.favoritedFrames), // .map(x=> { x.frameId)
            tap(() => { this.cd.markForCheck(); this.cd.detectChanges(); }),
            tap((favorites) => { this.debug.log(`%c[FrameHost] %c${favorites.length} %cfavorited frames are loaded!`, 'color: black; font-weight: bold;', 'color: green;', '') }),
            shareReplay(1)
        );

        
        this.isFavorite$ = this._session.selectedSession$.pipe(
            map(session => { 
                    const index = session.favoritedFrames?.findIndex(
                        x => x.frameId === this.frame.id 
                        && x.coatingId == this.frame.coating?.catalogCode 
                        && x.tintId == this.frame.tint?.catalogCode)

                    return index > -1 ? true : false;
                }                
            )
        );

        this.isSelected$ = combineLatest([
            this.selectedFrame$, 
            this._session.selectedSession$
        ]) 
        .pipe(
            map(([selectedFrame, session]) => {
                if (!selectedFrame || !this.frame) return false;
 
                const isSelected = (this.frame.id === selectedFrame.id &&
                this.frame.coating?.catalogCode == selectedFrame.coating?.catalogCode 
                && this.frame.tint?.catalogCode == selectedFrame.tint?.catalogCode);


                /*
                 * If user selects a frame and coating/coating. If selected combination is not present in sessions favorites,
                 * then we need to highlight a frame from 'Frames' tab.
                 */
                if (isSelected == true || !session.favoritedFrames) return isSelected;

                const sessionFavt = session.favoritedFrames.findIndex(x => (x.frameId === selectedFrame.id &&
                    x.coatingId == selectedFrame.coating?.catalogCode 
                    && x.tintId == selectedFrame.tint?.catalogCode)) > -1;
          
                return (this.frame.id === selectedFrame.id && !this.frame.coating && !this.frame.tint && !sessionFavt) ? true : false;
            })
        );

        /*  Check if availability status of a frame is set
            If not the frame appears as permanently not available
        */
        if(this.frame.availabilityStatus){
            this.isFrameAvailable = parseInt(FrameAvailability[this.frame.availabilityStatus]) == FrameAvailability.AVAILABLE;
            this.isFrameTemporaryUnavailable  = parseInt(FrameAvailability[this.frame.availabilityStatus]) == FrameAvailability.TEMPORARY_NOT_AVAILABLE;
            this.isFramePermanentlyUnavailable = parseInt(FrameAvailability[this.frame.availabilityStatus]) == FrameAvailability.PERMANENTLY_NOT_AVAILABLE;
        }else{
            this.isFrameAvailable = false;
            this.isFrameTemporaryUnavailable = false;
            this.isFramePermanentlyUnavailable = true;
        }
        
    }

    ngOnChanges(changes) {
        this.debug.log(`%c[FrameHost]%c [Change] %cChanges made in FrameHostComponent: `, 'color: black; font-weight: bold;', 'color: orange;', changes);
        this.cd.markForCheck();
    }

    public getRecommendedFrameImage(frameRecommendation) {
        return defer(() =>this._frames.getFrameThumbnail(frameRecommendation)|| of(null));
    }

    public favoriteSliderFrame(frame: Frame) {
        event.preventDefault();
        event.cancelBubble = true;

        this.gtm.pushEvent(GTMCustomEvents.viewer_slider_favourites);

        const subscriber = this._session.selectedSession$.pipe(
            first(),
            mergeMap(session => {
                this.isFramefavorizing$.next(true);
                const index = session.favoritedFrames.findIndex(e => e.frameId === frame.id && e.coatingId == frame.coating?.catalogCode && e.tintId == frame.tint?.catalogCode);
                const favFrameListIndex=this._frames.listFavFramesSession?.findIndex(f=>f.frameId === frame.id && f.coatingId == frame.coating?.catalogCode && f.tintId ==  frame.tint?.catalogCode);
                let favorites;
                if (index === -1) {
                    const favtFrame = { frameId: frame.id, coatingId: null, tintId: null, lastUpdate:new Date(Date.now()) } as FavoriteFrame;
                    this._frames.listFavFramesSession= [...this._frames.listFavFramesSession,favtFrame];
                    favorites = [...session.favoritedFrames,... this._frames.listFavFramesSession];
                } else {                     
                    session.favoritedFrames.splice(index, 1);                      
                    if(favFrameListIndex !=-1)this._frames.listFavFramesSession?.splice(favFrameListIndex,1);
                    favorites = [...session.favoritedFrames,... this._frames.listFavFramesSession];
                }
                let listFavFramesSession=JSON.parse(JSON.stringify(this._frames.listFavFramesSession));
                    return this._frames.updateSessionFavorites(favorites,session.id, listFavFramesSession);
            }),
            finalize(() => {
                subscriber.unsubscribe();
                setTimeout(() => {
                    this.isFramefavorizing$.next(false);
                  }, 2000);
                this.cd.markForCheck();
            })
        ).subscribe();
    }

    public toggleCompareButton(frameId: string) {
        this.compareFrame = frameId;
        let compareTint = null;
        let compareCoating = null;

        if(this.frame.tint) compareTint = this.frame.tint?.catalogCode;
        if(this.frame.coating) compareCoating = this.frame.coating?.catalogCode;
       
        this.compareList.push(this.compareFrame, compareTint, compareCoating);
        this.onCompare.emit(this.compareList);
    }

    public removeRecommendation(frameId: string) {
        event.preventDefault();
        event.cancelBubble = true;

        const subscriber = this._session.selectedSession$.pipe(
            first(),
            mergeMap(session => {
                return this._frames.removeRecommendation(frameId,session.id);
            }),
            finalize(() =>
                subscriber.unsubscribe()
            )
        ).subscribe();
    }

}
