import { FavoriteFrame } from './../../models/avatarcreationsession';
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FrameDesign } from '../../models/framedata';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { filter, flatMap, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { of, combineLatest, BehaviorSubject, Observable, pipe, ReplaySubject, defer } from 'rxjs';
import { ECPConfirmOrderDialogComponent } from '../../dialogs/confirm/confirm.dialog.component';
import { FrameInfo, FrameSelectionMail } from '../../models/frame-selection-mail.model';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationInsightsService } from '../../../services/applicationInsights.Service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ZeissIdBase, ZeissIdToken } from 'visauto-auth';
import { GoogleTagManagerService } from '../../services/gtm.service';
import { ConsumerAppEnvironment } from 'visenvironment';
import { DebugService } from '../../../services/debug.service';
import { Frame } from '../../models/frame.model';
import { FrameSelectionDialogComponent } from '../../dialogs/frame-selection/frame-selection.dialog.component';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { CoatingObj, TintObj } from '../../../configs/lensSettings.mock';
import { InteractionType, StatisticService, TintCoatingInteraction } from '../../../services/statistic.service';
import { SessionService } from '../../services/session.service';
import { FrameService } from '../../services/frame.service';
import { TintsCoatingsService } from '../../services/tints-coatings.service';
import { ViewerService } from '../../services/viewer.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-order-page',
    templateUrl: './order.page.component.html',
    styleUrls: ['./order.page.component.scss']
})
export class OrderPageComponent implements OnInit {

    public loading: boolean = false;

    public sessionId;
    public frameId;

    public sourceView: 'viewer' | 'gallery' | 'compareLeft' | 'compareRight';

    private forceCloseFlag = false;

    public selectedFrameWithTintAndCoating$: BehaviorSubject<FavoriteFrame[]> = new BehaviorSubject([]);

    //public frameImage$: ReplaySubject<string> = new ReplaySubject(1);

    private selectedFramesSync$: BehaviorSubject<Frame[]> = new BehaviorSubject([]);

    public selectedFrames$: Observable<Frame[]>;
    public orderCount$: Observable<number>;

    public addableFrames$ = combineLatest([this._frames.favoritedFrames$, this._frames.dmtRecommendedFrames$, this.selectedFrameWithTintAndCoating$]).pipe(
        map(([favorites, recommendations, selection]) => {
            const favoritesLength = favorites.length === 1;
            const recommendationsLength = recommendations.length === 1;

            const inFavorites = favorites.find(f => f.id===selection[0].frameId && f.coating?.catalogCode == selection[0].coatingId && f.tint?.catalogCode == selection[0].tintId)!==undefined;
            const inRecommendations = recommendations.find(r => r.id===selection[0].frameId)!==undefined;

            //There is just one favorite - the selected frame
            const onlyFavorite = favoritesLength&&inFavorites;

            // There are no favorites or just the selected frame-favorite
            const noaddablefav = onlyFavorite || favorites.length===0;

            // There is just one recommendation - the selected frame
            const onlyRecommendation = recommendationsLength&&inRecommendations;

            //There are no recommendations or just the selected recommended frame
            const noaddablerec = onlyRecommendation || recommendations.length===0;

            return !noaddablefav || !noaddablerec;
 
        })
    )

    public origin: string ;
    public mode: string = 'favorite';

    public alreadySubmitted: boolean = false;

    public loading$: Observable<boolean> = combineLatest([this._frames.allFrames$, this._viewer.ecpSettings$, this.selectedFrameWithTintAndCoating$]).pipe(
        map(([allframes, settings, selectedFrames]) => {
            return !((allframes?.length > 0) && (settings !== null) && (selectedFrames?.length > 0));
        }),
        startWith(true)
    );

    public selectedCoating: CoatingObj = null;
    public selectedTint: TintObj = null;

    public leftCoating: CoatingObj = null;
    public leftTint: TintObj = null;
    public rightCoating: CoatingObj = null;
    public rightTint: TintObj = null;

    public translationObjName: string = 'localization';

    constructor(
        public route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private translate: TranslateService,
        private appInsight: ApplicationInsightsService,
        private oAuth: OAuthService,
        private gtm: GoogleTagManagerService,
        private debug: DebugService,
        private snack: MatSnackBar,
        private errorHandler: ErrorHandler,
        private formatPrice:FormatPricePipe,
        private stats: StatisticService,
        public _session: SessionService,
        public _tintsCoatings: TintsCoatingsService,
        public _viewer:ViewerService,
        private _frames:FrameService

    ) {
        this.sessionId = this.route.snapshot.params.sessionId;
        this.frameId = this.route.snapshot.params.frameId;

        this.selectedFrameWithTintAndCoating$.next(
            [
                {
                    frameId: this.route.snapshot.params.frameId,
                    tintId: this.route.snapshot.queryParams.tint,
                    coatingId: this.route.snapshot.queryParams.coating
                } as FavoriteFrame
            ]);       

        this.forceCloseFlag = false;

    }

    public favoritesCount$ = this._frames.favoritedFrames$.pipe(
        map(frames => frames?.length || 0)
    )

    private get userId() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return base.accountId;
    }
    
    async ngOnInit() {
        await this._session.fetchSessionsList();
        
        try {
            await this._session.setSession(this.sessionId);
        } catch (error) {
            this.router.navigate(['/profile']);
            return;
        }

        // fetch tint and coating data from database.
        await this._tintsCoatings.getTintsAndCoatings();

        await this._frames.initAllFrames(this._session.selectedSession$.getValue().opticianCustomerNumber);
        await this._viewer.initEcpSettings();

        const initCampaignRcommendations =  this._frames.initCampaignRecommendations();
        const initFavoritedFrames = this._frames.initFavoritedFrames();
        const result = [await initCampaignRcommendations, await initFavoritedFrames];


        
        this.selectedFrames$ = combineLatest([this._frames.allFrames$, this.selectedFrameWithTintAndCoating$]).pipe(
            map(([allframes, framesWithLens]) => {
                const ids = framesWithLens.map(x => x.frameId);
                const selectedFrames = allframes.filter(f => ids.includes(f.id))
    
                const result = this._tintsCoatings.updateFrames(selectedFrames, framesWithLens); // allframes.filter(f => ids.includes(f.id))
                return result;
            }),
            tap(
                frames => this.selectedFramesSync$.next(frames)
            )
        );
        this.orderCount$ =this.selectedFrames$.pipe(
            map(frames => frames?.length || 0)
        )
        this.sourceView = this.route.snapshot.queryParams?.from;
        this.selectedTint = this._tintsCoatings.getTintByCatalogCode(this.route.snapshot.queryParams?.tint);
        this.selectedCoating = this._tintsCoatings.getLensByCatalogCode(this.route.snapshot.queryParams?.coating);

        this.appInsight.logPageView('order', window.location.href);
        this.gtm.pageView('order');

        this.origin = this.route.snapshot.queryParams?.origin;

        if (this.origin === 'gallery') {
            this.mode = 'optician';
        } else if (this.route.snapshot.queryParams?.from == 'gallery') {
            if (this.route.snapshot.queryParams?.mode)
                this.mode = this.route.snapshot.queryParams?.mode
            else
                this.mode = 'optician';
        }
        
        if (this.route.snapshot.queryParams?.from === 'compareRight') {
            this.leftCoating = this.route.snapshot.queryParams?.firstCoating;
            this.leftTint = this.route.snapshot.queryParams?.firstTint;
        }else if (this.route.snapshot.queryParams?.from === 'compareLeft'){
            this.rightCoating = this.route.snapshot.queryParams?.secondCoating;
            this.rightTint= this.route.snapshot.queryParams?.secondTint;
        }
    }

    public getFrameName() {

        return this._frames.allFrames$.pipe(
            flatMap(frames => frames),
            filter(frame => frame.id === this.frameId)
        );
    }


    public getFrameImage(frameId) {        
        return defer(() =>this._frames.getFrameThumbnail(frameId)|| of(null));
    }

    public getFrameDesign(): FrameDesign {
        return JSON.parse(sessionStorage.getItem('tmpfrm'));
    }

    public openECPDialog() {
        let dialogHost = null;
        if (window.innerWidth >= 601) {
            dialogHost = this.dialog.open(ECPConfirmOrderDialogComponent, { width: '800px', data: { sessionId: this.sessionId }, autoFocus: false });
        } else {
            dialogHost = this.dialog.open(ECPConfirmOrderDialogComponent, { width: '100vw', data: { sessionId: this.sessionId }, autoFocus: false });
        }

        dialogHost.afterClosed().subscribe((action: { decision: boolean, comment?: string }) => {
            this.loading = true;
            if (action) {
                this.debug.log(`[Dialog] [ECP-Confirm] Result of order confirmation is '${action.decision}' and message: `, action.comment);
                if (action.decision) {
                    this.appInsight.logAction('order', { sessionId: this.sessionId, userId: this.userId, frameId: this.frameId, userComment: action.comment });

                    this.sendSelection(action.comment).then(() => {
                        this.animateOrderComplete();
                    }).catch(error => {
                        this.errorHandler.handleError(error);
                        this.loading = false;
                    })
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
            
        });
    }

    public close() {
        let notSelectedFrame = this.route.snapshot.queryParams.frameId;

        if (this.sourceView === 'viewer') {
            this.router.navigate([`/viewer/${this.sessionId}`], { 
                queryParams: { 
                    frameId: this.frameId,
                    tint: this.selectedTint?.catalogCode,
                    coating: this.selectedCoating?.catalogCode 
                } });
        } else if (this.sourceView === 'gallery') {
            this.router.navigate([`gallery/${this.sessionId}`], {
                queryParams: {                   
                    mode: this.route.snapshot.queryParams.mode,
                    scrollPosition:this.route.snapshot.queryParams.scrollPosition,
                    endIndex:this.route.snapshot.queryParams.endIndex,
                    dmt:this.route.snapshot.queryParams.dmt
                },
            });
        } else if (this.sourceView === 'compareLeft') {
            this.router.navigate([`/compare/${this.sessionId}/${this.frameId}/${notSelectedFrame}`], { 
                queryParams: { 
                    from: this.origin, 
                    mode: this.mode,
                    leftCoating: this.selectedCoating?.catalogCode,
                    leftTint: this.selectedTint?.catalogCode,
                    rightCoating: this.rightCoating,
                    rightTint: this.rightTint,
                }});
        } else if (this.sourceView === 'compareRight') {
            this.router.navigate([`/compare/${this.sessionId}/${notSelectedFrame}/${this.frameId}`], { 
                queryParams: {
                    from: this.origin, 
                    mode: this.mode,
                    leftCoating: this.leftCoating,
                    leftTint: this.leftTint,
                    rightCoating: this.selectedCoating?.catalogCode,
                    rightTint: this.selectedTint?.catalogCode
                }});
        }
    }

    public forceClose() {
        this.forceCloseFlag = true;
        this.router.navigate(['/profile']);
    }

    private animateOrderComplete() {
        this.gtm.pageView('/order-success');
        document.getElementById('controls').style.display = 'none';
        this.alreadySubmitted = true;
        document.getElementById('confirmation').style.display = 'flex';
        this.loading = false;
    }
    
    public openFrameSelectionDialog() {
        this.dialog.open(FrameSelectionDialogComponent, {
            data: {
                frames: [...this.selectedFrameWithTintAndCoating$.getValue()]
            },
            autoFocus: false,
            maxHeight: '700px',
            maxWidth: '700px',
            minHeight: '30%'
        }).afterClosed().toPromise().then((frames) => {
            if(frames) {
                this.selectedFrameWithTintAndCoating$.next(frames);
            }
        });
        
    }

    public removeFrame(frame: Frame) {
        const frames = this.selectedFrameWithTintAndCoating$.getValue();
        const index = frames.findIndex(f => f.frameId === frame.id && f.tintId == frame.tint?.catalogCode && f.coatingId == frame.coating?.catalogCode);
        if(frames.length > 1) {
            frames.splice(index, 1);
            this.selectedFrameWithTintAndCoating$.next(frames);
        } else { 
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'warning',
                    heading: "errors.information",
                    message: "components.dialogs.frame-selection.error_msg",
                    btnIcon: 'close',
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center'
            });
        }
    }

    private async sendSelection(message: string) {
        if (!this.forceCloseFlag) {                    
            const session = this._session.selectedSession$.getValue();
            const userSelection = this.selectedFramesSync$.getValue();

            const data: FrameSelectionMail = {
                sessionId: session.id,
                opticianId: session.opticianId,
                comment: message,
                language: this.translate.currentLang,
                frameInfos: userSelection.map(f => {
                    return {
                        id: f.id,
                        brand: f.brand,
                        color: f.color,
                        frameSize: f.frameSize,
                        name: f.model,
                        tintId: f.tint?.catalogCode ? f.tint.catalogCode : null,
                        tintDescription: f.tint ? `${this._tintsCoatings.getTintUIName(f.tint)} ${this._tintsCoatings.getTintUIValue(f.tint)}`: null,
                        coatingId: f.coating?.catalogCode ? f.coating.catalogCode : null,
                        coatingDescription: f.coating ? this._tintsCoatings.getNameByLang(this.translationObjName, f.coating, this.translate.currentLang) : null
                    }
                })
            };

            for(const frameInfo of data.frameInfos) {
                this.stats.log({
                    type: InteractionType.TC_ORDER,
                    frameId: frameInfo.id,
                    coatingId: frameInfo.coatingId,
                    tintId: frameInfo.tintId,
                    sessionId: this.sessionId,
                    userId: this._session.selectedSession$.getValue()?.consumerId,
                    createdAt: new Date(Date.now())
                } as TintCoatingInteraction);
            }

            return this._frames.sendFrameSelection(data).toPromise();
        }
    }
   
}
